import React, { Component, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import makeCancellablePromise from 'make-cancellable-promise';
import { collection, getDoc, getDocs, query, where, doc, setDoc, addDoc, collectionGroup, onSnapshot, limit } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { setDefaultOptions } from 'date-fns';
import { id } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Loading from './../components/Loading';
import { db, auth } from './../helper/firebase';

const Login = React.lazy(() => import('./Login/Login')),
// const Login = React.lazy(() => {
//         return new Promise(resolve => {
//           setTimeout(() => resolve(import('./Login/Login')), 300000);
//         });
//       }),
      Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      dataSignIn: {
        service: '',
        phone: '',
        nameFirst: '',
        nameLast: ''
      },
      signIn: '',
      dataUser: '',
      // dataUser: {
      //   uid: '',
      //   phone: '',
      //   nameFirst: '',
      //   nameLast: '',
      //   nameFull: '',
      //   superAdmin: '',
      //   service: ''
      // },
      logOutLoading: false,
      setting: '',
      settingLoad: true
    }

    this.outLink = [{
      name: 'FAQ',
      link: 'https://indomakita.com/faq'
    }, {
      name: 'Ketentuan Penggunaan',
      link: 'https://indomakita.com/ketentuan-penggunaan'
    }, {
      name: 'Kebijakan Privasi',
      link: 'https://indomakita.com/kebijakan-privasi'
    }];
    
    // this.unsubscribeUser = '';
    this.unsubscribePri = '';
    this.cancellableCheckUser = '';
    this.cancellableAddUser = '';
    this.cancellableCheckPri = '';
    this.cancellableAddPri = '';
    this.cancellableLogout = '';
    this.cancellableSetting = '';

    this.updateSign = this.updateSign.bind(this);
    this.checkPri = this.checkPri.bind(this);
    this.listenUser = this.listenUser.bind(this);
    this.logOut = this.logOut.bind(this);
    this.loadSetting = this.loadSetting.bind(this);
  }

  componentDidMount(){
    setDefaultOptions({ locale: id });

    // this.unsubscribeUser = 
    onAuthStateChanged(auth, (user) => {
      // console.log(user);
      if(user){
        // console.log(this.state.dataSignIn);
        
        this.cancellableCheckUser = makeCancellablePromise(
          getDoc(
            doc(db, 'user', user.uid)
          )
        );

        this.cancellableCheckUser.promise.then((checkUserDoc) => {
          if(checkUserDoc.exists()){
            const userData = checkUserDoc.data();

            this.checkPri(user.uid, userData);
          }else{
            if(this.state.dataSignIn.phone){
              const newDate = new Date(),
                    newParam = {
                      userNameFirst: this.state.dataSignIn.nameFirst,
                      userNameLast: this.state.dataSignIn.nameLast,
                      userPhone: this.state.dataSignIn.phone,
                      userPhoneValid: true,
                      userEmail: null,
                      userEmailValid: null,
                      userGender: null,
                      userBirth: null,
                      userProvince: null,
                      userCity: null,
                      userDistrict: null,
                      userPostal: null,
                      userAddress: null,
                      userActive: true,
                      userStatus: true,
                      userAdd: newDate,
                      userEdit: newDate
                    };
  
              this.cancellableAddUser = makeCancellablePromise(
                setDoc(
                  doc(db, 'user', user.uid), newParam
                )
              );
  
              this.cancellableAddUser.promise.then((userResponse) => {
                // console.log(userResponse);

                this.checkPri(user.uid, newParam);
              }).catch((error) => {
                console.error(error);
          
                toast.error(() => (<>{error.code}<span>{error.message}</span></>));
                
                this.logOut();
              });
            }else{
              console.log('HERE!');
              toast.error(() => (<>Opps...<span>Kamu bukan admin.</span></>));

              this.logOut();
            }
          }
        }).catch((error) => {
          console.error(error);
    
          toast.error(() => (<>{error.code}<span>{error.message}</span></>));
          
          this.logOut();
        });
      }else{
        if(this.unsubscribePri){
          this.unsubscribePri();
        }

        this.setState({
          signIn: false,
          dataUser: '',
          // dataUser: {
          //   uid: '',
          //   phone: '',
          //   nameFirst: '',
          //   nameLast: '',
          //   nameFull: '',
          //   superAdmin: '',
          //   service: ''
          // }
        });
      }
    });

    this.loadSetting();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.signIn !== prevState.signIn){
      if(prevState.signIn !== ''){
        if(this.state.signIn){
          toast.success(() => (<>Masuk Berhasil<span>Hallo <strong>{this.state.dataUser.nameFull}</strong>!</span></>));
        }else{
          toast.success(() => (<>Keluar Berhasil<span>Selamat tinggal!</span></>));
        }
      }
    }
  }

  componentWillUnmount(){
    if(this.cancellableCheckUser){
      this.cancellableCheckUser.cancel();
    }

    if(this.cancellableAddUser){
      this.cancellableAddUser.cancel();
    }

    if(this.cancellableCheckPri){
      this.cancellableCheckPri.cancel();
    }

    if(this.cancellableAddPri){
      this.cancellableAddPri.cancel();
    }
    
    if(this.cancellableLogout){
      this.cancellableLogout.cancel();
    }

    // if(this.unsubscribeUser){
    //   this.unsubscribeUser();
    // }

    if(this.unsubscribePri){
      this.unsubscribePri();
    }
    
    if(this.cancellableSetting){
      this.cancellableSetting.cancel();
    }
  }

  updateSign(service, phone, nameFirst, nameLast){
    this.setState({
      dataSignIn: {
        service: service,
        phone: phone,
        nameFirst: nameFirst,
        nameLast: nameLast
      }
    });
  }

  checkPri(uid, userData){
    let theQuery = '';

    if(this.state.dataSignIn.service){
      theQuery = query(collection(db, 'user', uid, 'userPrivilege'), where('userPrivilegeApp', '==', 'indomakita.com'), where('userPrivilegeAdmin', '==', true), where('userPrivilegeService', '==', this.state.dataSignIn.service), limit(1));
    }else{
      theQuery = query(collection(db, 'user', uid, 'userPrivilege'), where('userPrivilegeApp', '==', 'indomakita.com'), where('userPrivilegeAdmin', '==', true), where('userPrivilegeService', '!=', null), limit(1));
    }

    this.cancellableCheckPri = makeCancellablePromise(
      getDocs(
        theQuery
      )
    );

    this.cancellableCheckPri.promise.then((checkPriDoc) => {
      if(checkPriDoc.size){
        this.listenUser(uid, userData, checkPriDoc.docs[0].id);
      }else{
        if(this.state.dataSignIn.service){
          this.cancellableCheckSuper = makeCancellablePromise(
            getDocs(
              query(
                collectionGroup(db, 'userPrivilege'), where('userPrivilegeApp', '==', 'indomakita.com'), where('userPrivilegeAdmin', '==', true), where('userPrivilegeService', '==', this.state.dataSignIn.service), limit(1)
              )
            )
          );
  
          this.cancellableCheckSuper.promise.then((superResponse) => {
            const newDate = new Date();
  
            this.cancellableAddPri = makeCancellablePromise(
              addDoc(collection(db, 'user', uid, 'userPrivilege'), {
                userPrivilegeApp: 'indomakita.com',
                userPrivilegeAdmin: true,
                userPrivilegeBrand: null,
                userPrivilegeStore: null,
                userPrivilegeService: this.state.dataSignIn.service,
                userPrivilegeSuper: superResponse.size === 0 ? true : false,
                userPrivilegeActive: true,
                userPrivilegeAdd: newDate,
                userPrivilegeEdit: newDate,
                userPrivilegeParent: doc(db, 'user', uid)
                }
              )
            );
    
            this.cancellableAddPri.promise.then((priResponse) => {
              this.listenUser(uid, userData, priResponse.id);
            }).catch((error) => {
              console.error(error);
        
              toast.error(() => (<>{error.code}<span>{error.message}</span></>));
              
              this.logOut();
            });
          }).catch((error) => {
            console.error(error);
      
            toast.error(() => (<>{error.code}<span>{error.message}</span></>));
            
            this.logOut();
          });
        }else{
          toast.error(() => (<>Opps...<span>Kamu bukan admin.</span></>));

          this.logOut();
        }
      }
    }).catch((error) => {
      console.error(error);

      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
      
      this.logOut();
    });
  }

  listenUser(uid, userData, priId){
    this.unsubscribePri = onSnapshot(doc(db, 'user', uid, 'userPrivilege', priId), (doc) => {
      const snapData = doc.data();

      if(snapData.userPrivilegeAdmin && snapData.userPrivilegeActive){
        this.setState({
          signIn: true,
          dataUser: {
            uid: uid,
            priId: priId,
            phone: userData.userPhone,
            nameFirst: userData.userNameFirst,
            nameLast: userData.userNameLast,
            nameFull: userData.userNameFirst + ' ' + userData.userNameLast,
            email: userData.userEmail,
            gender: userData.userGender,
            birth: userData.userBirth,
            province: userData.userProvince,
            city: userData.userCity,
            district: userData.userDistrict,
            postal: userData.userPostal,
            address: userData.userAddress,
            add: userData.userAdd,
            edit: userData.userEdit,
            superAdmin: snapData.userPrivilegeSuper,
            service: snapData.userPrivilegeService
          }
        });
      }else{
        this.unsubscribePri();
        this.logOut();
      }
    }, (error) => {
      console.error(error);

      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    
      this.logOut();
    });
  }

  logOut(){
    this.cancellableLogout = makeCancellablePromise(signOut(auth));

    this.cancellableLogout.promise.then(() => {
      // this.setState({
      //   signIn: false,
      //   dataUser: {
      //     uid: '',
      //     phone: ''
      //   }
      // });
    }).catch((error) => {
      console.error(error);
      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  loadSetting(){
    this.cancellableSetting = makeCancellablePromise(
      getDocs(
        query(
          collection(db, 'setting'), limit(1)
        )
      )
    );

    this.cancellableSetting.promise.then((settingSnapshot) => {
      if(settingSnapshot.size){
        const settingData = settingSnapshot.docs[0].data(),
              copyNow = (new Date()).getFullYear();

        this.setState({
          setting: {
            url: 'https://servis.indomakita.co.id',
            dateFormat: settingData.settingAdminDate,
            dateTime: settingData.settingAdminTime,
            dateTimeFormat: settingData.settingAdminDate + ' ' + settingData.settingAdminTime,
            safeMode: false,
            desc: settingData.settingService,
            copy: settingData.settingCopyright === copyNow ? settingData.settingCopyright : settingData.settingCopyright + ' - ' + copyNow
          },
          settingLoad: false
        });
      }
    }).catch((error) => {
      console.error(error);

      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  render(){
    if(this.state.signIn === ''){
      return(
        <Loading text="Memeriksa autentikasi..." page />
      );
    }else{
      if(this.state.settingLoad){
        return(
          <Loading text="Memuat pengaturan..." page />
        );
      }else{
        return(
          <Suspense fallback={
            <Loading text="Memuat halaman..." page />
          }>
            <Routes>
              <Route path="/login" element={
                this.state.signIn ? (<Navigate to="/admin" />) : (<Login updateSign={this.updateSign} setting={this.state.setting} outLink={this.outLink} />)
              } />
              <Route path="/admin/*" element={
                this.state.signIn ? (<Dashboard dataUser={this.state.dataUser} setting={this.state.setting} logOut={this.logOut} outLink={this.outLink} />) : (<Navigate to="/login" />)
              } />
              <Route path="*" element={
                this.state.signIn ? (<Navigate to="/admin" />) : (<Navigate to="/login" />)
              } />
            </Routes>
          </Suspense>
        );
      }
    }
  }
}

export default App;