import React, { Component } from 'react';

import {ReactComponent as LoadingIcon} from './../assets/img/loading.svg';

class Loading extends Component{
  constructor(){
    super();
    
    this.state = {
      // focus: false
    }

    // this.cancellableCheck = '';

    // this.checkFocus = this.checkFocus.bind(this);
  }

  componentDidMount(){}

  componentDidUpdate(prevProps, prevState){
    // if(this.props.value !== prevProps.value){}
  }

  componentWillUnmount(){}

  render(){
    return(
      <div className={`loading ${this.props.page ? 'page' : ''}`}>
        <LoadingIcon />
        <div>{this.props.text}</div>
      </div>
    );
  }
}

export default Loading;